<template>
    <div>
        <!-- toolbar -->
        <b-button-toolbar class="d-flex justify-content-between m-2 p-0">
            <div>
                <!-- button: seasons -->
                <b-button size="sm" variant="secondary" @click="openModalSeason()">{{$tc('adventure.season'), 1}}</b-button>
                <!-- currencies -->
                <b-form-select
                    id="product_packaging"
                    v-model="getCurrencyId"
                    :options="getCurrencies"
                    class="mb-2 ml-sm-l ml-1 mb-sm-0 bg-white"
                    size="sm"
                    style="width: 50px"
                ></b-form-select>
            </div>
            <!-- button: add -->
            <b-button size="sm" class="ml-1" variant="secondary" @click="addRecord()">
                <b-icon-plus
                    scale="2"
                    variant="white"
                ></b-icon-plus>
            </b-button>
        </b-button-toolbar>
        <!-- table -->
        <b-table
            id="main-table"
            :fields="tableColumns"
            :items="tableItems"
            bordered head-row-variant="dark" hover outlined
            responsive
            striped
            table-variant="light"
            thead-class="text-center"
        >
            <!-- columns -->
            <template v-slot:[`cell(${column.key})`]="row" v-for="column in tableColumns">
                <!-- price unit id -->
                <div v-if="row.field.key === 'price_unit_id'" class="d-flex justify-content-around">
                    <b-form-select
                        id="priceUnitId"
                        v-model="recordPriceList[row.index][row.field.key]"
                        :options="getPriceUnits"
                        size="sm"
                        style="min-width: 120px;"
                    ></b-form-select>
                </div>

                <!-- age -->
                <div v-else-if="row.field.key === 'age'"
                     class="d-flex justify-content-center align-items-center"
                     style="height: 30px;"
                >
                    {{row.item.age}}
                </div>

                <!-- action -->
                <div v-else-if="row.field.key === 'action'"
                     class="d-flex justify-content-center align-items-center"
                     style="height: 30px;"
                >
                    <!-- delete -->
                    <b-icon-trash
                        v-b-tooltip.hover
                        :title="$t('form.delete')"
                        scale="1.2"
                        style="cursor: pointer;"
                        variant="danger"
                        @click="recordDelete(row.index)"
                    ></b-icon-trash>
                </div>

                <!-- prices -->
                <div v-else class="d-flex justify-content-around">
                    <b-input-group :prepend="getCurrencyPosition.prepend" :append="getCurrencyPosition.append" size="sm"
                                   style="min-width: 80px;">
                        <b-form-input
                            v-model.number="recordPriceList[row.index].seasonsPrices[row.field.key]"
                            class="text-right no-spinners"
                            size="sm"
                            type="number"
                            variant="primary"
                            :lazy-formatter="true"
                            :formatter="__formatDecimalNumber"
                            @keypress="__onlyDecimalKey($event)"
                        ></b-form-input>
                    </b-input-group>
                </div>
            </template>
        </b-table>
        <!-- modalFormXxx -->
        <modal-form-season
            :component-key="componentKey"
            :modal-bus.sync="modalBus"
            :parent-records="getPriceListSeasons"
            @eventUpdateSeasons="updateSeasons"
        ></modal-form-season>
    </div>
</template>

<script>
import Vue from "vue";
import ModalFormSeason from "@/components/adventure/experience/elements/ModalFormSeason";

export default {
    name: "ElementPriceList",
    components: {ModalFormSeason},
    props: {
        title: {
            type: String,
            default() {
                return this.$t('form.tab.locale');
            }
        },
        dataCurrencies: {
            type: Array
        },
        dataPriceUnits: {
            type: Array
        },
        recordPriceListSeasons: {
            type: Array,
            default() {
                return []
            }
        },
        recordPriceList: {
            type: Array,
            default() {
                return []
            }
        },
        recordCurrencyId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            componentKey: null,
            table: {
                items: [],
                fields: [],
                columns: [
                    {
                        key: 'price_unit_id',
                        label: this.$t('adventure.per_unit'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'age',
                        label: this.$t('adventure.age'),
                        sortable: false,
                        show: true,
                    },
                ],
                sortBy: 'id',
                sortDesc: false,
            },
            // <-- modal season -->
            modalBus: new Vue(),
        }
    },
    mounted() {
        this.componentKey = this.__randomKey();
        this.addDefaultRecord();
    },
    methods: {
        getAge(priceUnitId) {
            if (this.__isNull(this.dataPriceUnits)) {
                return null;
            }
            // <--  -->
            let age = null;
            this.dataPriceUnits.every(v => {
                if (v.id === priceUnitId) {
                    if (!this.__isNull(v.meta.age_min)) {
                        age = `${v.meta.age_min}+`;
                        return false;
                    } else if (!this.__isNull(v.meta.age_max)) {
                        age = v.meta.age_max;
                        return false;
                    }
                }
                return true;
            });
            // <--  -->
            return age;
        },
        // <-- recordPriceList -->
        addDefaultRecord(){
            if(this.recordPriceList.length > 0){
                return;
            }
            this.addRecord();
        },
        addRecord() {
            this.recordPriceList.push({
                currency_id: this.recordCurrencyId,
                price_unit_id: null,
                seasonsPrices: {},
            });
        },
        recordDelete(index) {
            this.recordPriceList.splice(index, 1);
        },
        // <-- season -->
        openModalSeason() {
            this.modalBus.$emit('modalOpen', 0);
        },
        updateSeasons(data) {
            this.$emit('eventUpdatePriceListSeasons', data);
        }
    },
    computed: {
        // <-- data -->
        getPriceUnits() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.dataPriceUnits)) {
                return [
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    }
                ];
            }
            // <--  -->
            let items = this.dataPriceUnits;
            let text, age = null;
            items.forEach(item => {
                text = `(${item.meta.name})`;
                // <-- locale -->
                if (!this.__isNull(item.locale.name) && !this.__isNull(item.locale)) {
                    text = item.locale.name;
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- sort -->
            /*                array = array.sort(function (a, b) {
                            let fa = a.text.toLowerCase(),
                                fb = b.text.toLowerCase();

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        });*/
            // <-- add placeholder on top -->
            array.unshift({
                text: this.$t('form.choose_placeholder'),
                value: null
            });
            // <--  -->
            return array;
        },
        getPriceListSeasons() {
            let array = this.__clone(this.recordPriceListSeasons);
            // <-- sort -->
            array = array.sort(function (a, b) {
                // <-- default season -->
                if (a.key === 'default') {
                    return 1;
                }
                // <--  -->
                let fa = a.start,
                    fb = b.start;
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            // <--  -->
            return array;
        },
        // <-- currency -->
        getCurrencyId: {
            get() {
                return this.recordCurrencyId;
            },
            set(value) {
                this.$emit('eventSetCurrencyId', value);
            }
        },
        getCurrencies() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.dataCurrencies)) {
                return [
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    }
                ];
            }
            // <--  -->
            let items = this.dataCurrencies;
            let text, age = null;
            items.forEach(item => {
                text = item.content.sign;
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- add placeholder on top -->
            /*array.unshift({
                text: this.$t('form.choose_placeholder'),
                value: null
            });*/
            // <--  -->
            return array;
        },
        getCurrencyPosition() {
            if (this.__isNull(this.dataCurrencies) || this.tableColumns.length - 3 > 6) {
                return {
                    prepend: null,
                    append: null
                }
            }
            // <--  -->
            let prepend = null;
            let append = null;
            this.dataCurrencies.every(v => {
                if (v.id === this.recordCurrencyId) {
                    switch (v.content.position) {
                        case 'prepend':
                            prepend = v.content.sign;
                            break;
                        case 'append':
                            append = v.content.sign;
                            break;
                    }
                    return false;
                }
                return true;
            });
            // <--  -->
            return {
                prepend: prepend,
                append: append
            }
        },
        // <-- table -->
        tableColumns() {
            let columns = this.table.columns;
            // let columnsShow = this.tableToolbar.response.columnsShow;
            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            columns.forEach(column => {
                values.push({
                    key: column.key,
                    label: column.label,
                    formatter: column.formatter,
                    sortable: column.sortable,
                    show: column.show,
                });
            });
            // <-- add columns seasons -->
            let dateStart, dateEnd;
            let dateStartString, dateEndString;
            this.getPriceListSeasons.forEach(season => {
                // <-- default season -->
                if (season.key === 'default') {
                    values.push({
                        key: season.key,
                        label: this.$t(`form.default`),
                    });
                }
                // <-- date start -->
                else {
                    dateStart = new Date(season.start);
                    dateStartString = dateStart.toLocaleDateString(this.__getVueLocale(), {
                        month: "numeric",
                        day: "numeric"
                    })
                    // <-- date end -->
                    dateEnd = new Date(season.end);
                    dateEndString = dateEnd.toLocaleDateString(this.__getVueLocale(), {
                        month: "numeric",
                        day: "numeric"
                    })
                    // <--  -->
                    values.push({
                        key: season.key,
                        label: `${dateStartString}–${dateEndString}`,
                    });
                }
            });
            // <-- add column actions -->
            values.push({
                key: 'action',
                label: '',
                id: 0,
                sortable: false,
                thStyle: {width: '60px'}    // 90px for 3 icons
                // variant: 'danger',
            });
            // <--  -->
            return values;
        },
        tableItems() {
            if (this.__isNull(this.recordPriceList)) {
                return [];
            }
            // <--  -->
            let records = this.recordPriceList;
            // <-- check if exists -->
            // if (records.length === 0) {
            //     return [];
            // }
            // <--  -->
            let items = [];
            let item = {};
            let name;
            records.forEach(record => {
                // <-- set item -->
                item = {
                    currency_id: record.currency_id,
                    perUnitId: record.price_unit_id,
                    age: this.getAge(record.price_unit_id),
                }
                // <-- add seasons price -->

                // record.seasonsPrices.forEach(seasonPrice => {
                //     item[seasonPrice.seasonKey] = seasonPrice.price;
                // });
                // <--  -->
                items.push(item);
            });
            // <--  -->
            return items;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
