<template>
    <b-form-group
        :label="title"
        class="mb-0"
        label-class="font-weight-bold pt-0"
        label-size="md"
    >
        <b-card no-body>
            <b-tabs card pills>
                <b-tab v-for="locale in getDataLocale"
                       :active="locale.locale === $i18n.locale"
                       :title="locale.locale" class="border-danger"
                       style="background: white"
                >
                    <b-row>
                        <!-- column a -->
                        <b-col class="p-0 px-2 " cols="6">
                            <!-- title -->
                            <b-form-group
                                :label="$t('adventure.title')"
                                class="mb-2 mr-sm-02 mb-sm-0"
                                description=""
                                label-class="mb-0"
                                label-for="meta_title"
                            >
                                <b-form-input
                                    :id="'title_' + locale.locale"
                                    v-model.lazy="record[locale.locale].name"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <!-- column b -->
                        <b-col class="p-0 px-2 " cols="6">
                            <!-- subtitle -->
                            <b-form-group
                                :label="$t('form.subtitle')"
                                class="mb-2 mr-sm-02 mb-sm-0"
                                description=""
                                label-class="mb-0"
                                label-for="meta_subTitle"
                            >
                                <b-form-input
                                    :id="'subTitle_' + locale.locale"
                                    v-model.lazy="record[locale.locale].subtitle"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <!-- column a -->
                        <b-col class="p-0 px-2 " cols="6">
                            <!-- keyword -->
                            <b-form-group
                                :id="locale + '.group-record.keyword'"
                                class="mb-2 mr-sm-02 mb-sm-0"
                                label-class="mb-0"
                                :label="$t('form.keyword.label')"
                                :label-for="locale + 'keyword'"
                                description=""
                            >
                                <b-form-tags
                                    :id="locale + 'keyword'"
                                    v-model="record[locale.locale].keyword"
                                    :duplicateTagText="$t('form.keyword.duplicate')"
                                    :invalidTagText=null
                                    :placeholder="null"
                                    :tag-validator="validator"
                                    addButtonText="Dodaj"
                                    remove-on-delete
                                    separator=",;"
                                ></b-form-tags>
                            </b-form-group>
                        </b-col>
                        <!-- column b -->
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-form-group>
</template>

<script>
export default {
    name: "FormExperienceLocaleBasic",
    components: {},
    props: {
        title: {
            type: String,
            default() {
                return this.$t('form.tab.locale');
            }
        },
        data_locale: {
            type: Array
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        validator(tag) {
            return tag.length > 2;
        },
    },
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {
                        experience_type_id: null,
                        name: null,
                        subtitle: null,
                        keyword: null,
                    });
                }
            });
            // <--  -->
            return this.data_locale;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
