<template>
    <b-form-group
        :label="title"
        class="mb-0"
        label-class="font-weight-bold pt-0"
        label-size="md"
    >
        <b-card no-body>
            <b-tabs card pills>
                <b-tab v-for="locale in getDataLocale"
                       :active="locale.locale === $i18n.locale"
                       :title="locale.locale" class="border-danger"
                       style="background: white"
                >
                    <!-- toolbar -->
                    <b-button-toolbar class="d-flex justify-content-end mb-2 p-0">
                        <!-- dropdown language basis -->
                        <b-dropdown
                            id="dropdown-language_basis"
                            v-model="languageBasis"
                            right
                            offset="1"
                            size="sm"
                            class="mr-1"
                            :title="$t('adventure.language_basis')"
                        >
                            <!--                            no-caret-->
                            <template v-slot:button-content>
                                <b-icon
                                    aria-hidden="false"
                                    icon="chat-square-text"
                                ></b-icon>
                            </template>
                            <b-dropdown-item-button
                                size="sm"
                                variant="secondary"
                                @click="languageBasis = null;"
                                :active="languageBasis === null"
                            >
                                {{$t('form.off')}}
                            </b-dropdown-item-button>

                            <b-dropdown-divider></b-dropdown-divider>

                            <b-dropdown-item-button
                                v-for="locale in getDataLocale"
                                size="sm"
                                variant="secondary"
                                @click="languageBasis = locale.locale;"
                                :active="languageBasis === locale.locale"
                            >
                                {{locale.locale}}
                            </b-dropdown-item-button>
                        </b-dropdown>
                        <!-- buttons: collapse all -->
                        <b-button class="px-2 py-0 mr-1 button-header" @click="setCollapseAll(false)">_
                        </b-button>
                        <!-- button: expand -->
                        <b-button class="px-1 py-0 mr-1 button-header" @click="setCollapseAll(true)">
                            <b-icon-app
                                scale="0.7"
                            ></b-icon-app>
                        </b-button>
                        <!-- button: add -->
                        <b-button size="sm" variant="secondary" @click="addStep(locale)">
                            <b-icon-plus
                                scale="2"
                                variant="white"
                            ></b-icon-plus>
                        </b-button>
                    </b-button-toolbar>
                    <!-- steps -->
                    <b-row>
                        <b-col cols="12" class="px-1" v-if="record[locale.locale] !== undefined">
                            <draggable
                                v-model="record[locale.locale][json_key]"
                                handle=".draggable-handle_task"
                                @start="drag=true"
                                @end="drag=false"
                                @change="draggableChangeStep(locale.locale)"
                            >
                                <element-experience-task-step-question
                                    v-for="(step, index) in record[locale.locale][json_key]"
                                    :locale="locale.locale"
                                    :index="index"
                                    :record="step"
                                    :collapse-tasks.sync="collapseTasks"
                                    :language-basis-answers.sync="languageBasisAnswers"
                                    :language-basis="languageBasis"
                                    :language-basis-record="record[languageBasis]"
                                    @eventRemoveStep="removeStep"
                                    @eventAddAnswer="addAnswer"
                                    @eventRemoveAnswer="removeAnswer"
                                    @eventSyncAnswer="syncAnswer"
                                ></element-experience-task-step-question>
                            </draggable>
                        </b-col>
                    </b-row>
                    <!-- prize -->
                    <b-row class="mt-2">
                        <b-col cols="6">
                            <!-- prize -->
                            <!--                            <b-form-group-->
                            <!--                                class="mb-2 mr-sm-02 mb-sm-0"-->
                            <!--                                label-class="mb-0"-->
                            <!--                                :label="$t('form.title')"-->
                            <!--                                label-for="tasks.title_id"-->
                            <!--                                description=""-->
                            <!--                            >-->
                            <!--                                <b-form-input-->
                            <!--                                    id="tasks.title_id"-->
                            <!--                                    v-model="record.title"-->
                            <!--                                ></b-form-input>-->
                            <!--                            </b-form-group>-->
                            <!--                        </b-col>-->
                            <!--                        <b-col cols="6">-->
                            <!-- attempts points -->
                            <!--                            <b-form-group-->
                            <!--                                id="group-attempts-points"-->
                            <!--                                class="mb-2 mr-sm-02 mb-sm-0"-->
                            <!--                                label-class="mb-0"-->
                            <!--                                :label="$t('adventure.attempt_point')"-->
                            <!--                                label-for="attempts-points"-->
                            <!--                                description=""-->
                            <!--                            >-->
                            <!--                                <b-form-tags v-model="record.attemptPoints" class="mb-2">-->
                            <!--                                    <template v-slotBrisi="{tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag}">-->
                            <!--                                        <b-input-group class="mb-2">-->
                            <!--                                            <b-form-input-->
                            <!--                                                v-bind="inputAttrs"-->
                            <!--                                                v-on="inputHandlers"-->
                            <!--                                                :placeholder="$t('adventure.attempt_point_placeholder')"-->
                            <!--                                                class="form-control"-->
                            <!--                                                @keypress="__onlyIntegerKey($event)"-->
                            <!--                                            ></b-form-input>-->
                            <!--                                            <b-input-group-append>-->
                            <!--                                                <b-button @click="addTag()" variant="primary">{{$t('form.add')}}</b-button>-->
                            <!--                                            </b-input-group-append>-->
                            <!--                                        </b-input-group>-->
                            <!--                                        <div class="d-inline-block" >-->
                            <!--                                            <b-form-tag-->
                            <!--                                                v-for="tag in tags"-->
                            <!--                                                @remove="removeTag(tag)"-->
                            <!--                                                :key="tag"-->
                            <!--                                                :title="tag"-->
                            <!--                                                :variant="tagVariant"-->
                            <!--                                                class="mr-1"-->
                            <!--                                            >{{ tag }}</b-form-tag>-->
                            <!--                                        </div>-->
                            <!--                                    </template>-->
                            <!--                                </b-form-tags>-->


                            <!--                            </b-form-group>-->
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-form-group>
</template>

<script>
import draggable from 'vuedraggable'
import ElementExperienceTaskStepQuestion
    from "@/components/adventure/experience/elements/ElementExperienceTaskStepQuestion";

export default {
    name: "FormExperienceLocaleTask",
    components: {draggable, ElementExperienceTaskStepQuestion},
    props: {
        title: {
            type: String,
            default() {
                return this.$t('form.tab.locale');
            }
        },
        data_locale: {
            type: Array
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            json_key: 'tasks',
            collapseTasks: {},
            languageBasisAnswers: {},
            languageBasis: null,
        }
    },
    mounted() {
        this.addStepDefault();
        // <-- set collapseKey -->
        this.syncCollapseKeys();
    },
    methods: {
        addStepDefault() {
            if (this.__isNull(this.record) || this.record[this.$i18n.locale] === undefined || this.record[this.$i18n.locale][this.json_key].length > 0) {
                return;
            }
            // <--  -->
            let locale = null;
            this.getDataLocale.every(v => {
                if (this.$i18n.locale === v.locale) {
                    locale = v;
                    return false;
                }
                return true;
            });
            // <--  -->
            this.addStep(locale);
        },
        // <-- steps -->
        syncStep(refLocale) {
            let refRecords = this.record[refLocale][this.json_key];
            // <--  -->
            let array = [];
            let valueRecords = [];
            let exists = false;
            this.getDataLocale.forEach((valueLocale, index) => {
                if (valueLocale.locale !== refLocale) {
                    valueRecords = this.record[valueLocale.locale][this.json_key];
                    // <--  -->
                    refRecords.forEach((refRecord, refIndex) => {
                        valueRecords.every(valueRecord => {
                            if (refRecord.together.key === valueRecord.together.key) {
                                array.push(valueRecord);
                                exists = true;
                                return false;
                            }
                            return true;
                        });
                        if (!exists) {
                            let newRecord = {
                                together: refRecord.together,
                                unique: this.__clone(refRecord.unique)
                            };
                            array.push(newRecord);
                        }
                        exists = false;
                    });
                    // <--  -->
                    this.record[valueLocale.locale][this.json_key] = array;
                    array = [];
                }
            });
        },
        addStep(locale) {
            let stepKey = this.__createUniqueKey(0);
            this.$set(this.collapseTasks, stepKey, true);
            // <--  -->
            this.record[locale.locale][this.json_key].push(
                {
                    together: {
                        key: stepKey,
                        type: 'question',
                        answerBullet: null,     // <-- npr: 1: 1.) 2.)...; 2: a). b,...; 3: •...; -->
                        answersRandom: true,
                    },
                    unique: {
                        title: null,
                        description: null,
                        question: null,
                        answers: [],
                    }
                }
            );
            this.syncStep(locale.locale);
        },
        removeStep(step) {
            let records = this.record[step.locale][this.json_key];
            // <--  -->
            records.every((v, index) => {
                if (v.together.key === step.key) {
                    records.splice(index, 1);
                    return false;
                }
                return true;
            });
            // <--  -->
            this.syncStep(step.locale);
        },
        draggableChangeStep(locale) {
            this.syncStep(locale);
        },
        getStepIndex(locale, key) {
            let stepIndex = null;
            this.record[locale][this.json_key].every((v, index) => {
                if (v.together.key === key) {
                    stepIndex = index;
                    return false;
                }
                return true;
            });
            return stepIndex;
        },
        // <-- answers -->
        syncAnswer(data) {
            let refRecord = this.record[data.locale][this.json_key];
            let stepIndex = this.getStepIndex(data.locale, data.stepKey);
            let refAnswers = refRecord[stepIndex].unique.answers;
            // <--  -->
            let valueAnswers = [];
            let exists = false;
            let array = [];
            this.getDataLocale.forEach((valueLocale, index) => {
                if (valueLocale.locale !== data.locale) {
                    valueAnswers = this.record[valueLocale.locale][this.json_key][stepIndex].unique.answers;
                    // <--  -->
                    array = [];
                    refAnswers.forEach((refAnswer, refIndex) => {
                        exists = false;
                        valueAnswers.every(valueAnswer => {
                            if (refAnswer.key === valueAnswer.key) {
                                array.push(
                                    {
                                        key: valueAnswer.key,
                                        text: valueAnswer.text,
                                        points: refAnswer.points
                                    }
                                );
                                exists = true;
                                return false;
                            }
                            return true;
                        });
                        if (!exists) {
                            array.push(
                                {
                                    key: refAnswer.key,
                                    text: refAnswer.text,
                                    points: refAnswer.points
                                }
                            );
                        }
                    });
                    this.record[valueLocale.locale][this.json_key][stepIndex].unique.answers = array;
                }
            });
        },
        addAnswer(data) {
            let stepIndex = this.getStepIndex(data.locale, data.stepKey);
            // <--  -->
            this.record[data.locale][this.json_key][stepIndex].unique.answers.push(
                {
                    key: this.__createUniqueKey(0),
                    points: null,
                    text: null,
                },
            );
            this.syncAnswer(data);
        },
        removeAnswer(data) {
            let stepIndex = this.getStepIndex(data.locale, data.stepKey);
            let record = this.record[data.locale][this.json_key][stepIndex];
            let answers = record.unique.answers;
            answers.every((answer, index) => {
                if (answer.key === data.answerKey) {
                    answers.splice(index, 1);
                    return false;
                }
                return true;
            });
            this.syncAnswer(data);
        },
        // <-- collapse -->
        syncCollapseKeys() {
            if(this.record[this.$i18n.locale] === undefined){
                return;
            }
            // <--  -->
            this.record[this.$i18n.locale][this.json_key].forEach((record, index) => {
                if (!Object.keys(this.collapseTasks).includes(record.together.key)) {
                    this.$set(this.collapseTasks, record.together.key, index === 0 ? true : false);
                }
            });
        },
        setCollapseAll(value) {
            Object.keys(this.collapseTasks).forEach(key => {
                this.collapseTasks[key] = value;
            });
        }
    },
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {});
                }
                if (this.record[locale.locale][this.json_key] === undefined) {
                    this.$set(this.record[locale.locale], this.json_key, []);
                }
            });
            // // <-- set collapseKey -->
            // this.syncCollapseKeys();
            // <--  -->
            return this.data_locale;
        },
    },
    filters: {},
    watch: {
        'languageBasis': {
            handler: function (value, oldValue) {
                this.languageBasisAnswers = {};
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
