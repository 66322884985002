<template>
    <b-modal
        :id="$options.name  + this.componentKey"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        size="lg"
        @ok="formSave"
        :scrollable="!getRecords > 3"
    >
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- table -->
                <b-table
                    id="main-table"
                    :fields="table.columns"
                    :items="getRecords"
                    table-variant="light"
                    thead-class="text-center"
                    head-row-variant="dark" hover outlined bordered
                >
                    <!-- header: action -->
                    <template #head(action)="head">
                        <b-button right size="sm" variant="secondary" @click="rowAdd()">
                            <b-icon-plus
                                scale="2"
                                variant="white"
                            ></b-icon-plus>
                        </b-button>
                    </template>
                    <!-- default season: row -->
                    <template #top-row="row">
                        <b-td>{{getRecordDefault.label}}</b-td>
                        <b-td colspan="2">{{getRecordDefault.description}}</b-td>
                        <b-td></b-td>
                    </template>
                    <!-- cell: label -->
                    <template #cell(label)="row">
                        <b-form-input v-model="row.item.label" size="sm"></b-form-input>
                    </template>
                    <!-- cell: start -->
                    <template #cell(start)="row">
                        <b-form-datepicker
                            v-model="row.item.start"
                            :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                            :start-weekday="__localeContent.firstDayOfWeek"
                            :locale="__getVueLocale()"
                            label-help=""
                            label-no-date-selected=""
                            size="sm"
                        ></b-form-datepicker>
                    </template>
                    <!-- cell: start -->
                    <template #cell(end)="row">
                        <b-form-datepicker
                            v-model="row.item.end"
                            :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                            :start-weekday="__localeContent.firstDayOfWeek"
                            :locale="__getVueLocale()"
                            label-help=""
                            label-no-date-selected=""
                            size="sm"
                            :min="row.item.start"
                        ></b-form-datepicker>
                    </template>
                    <!-- cell: action -->
                    <template #cell(action)="row">
                        <div class="d-flex justify-content-center align-items-center"
                             style="height: 30px;"
                        >
                            <!-- delete -->
                            <b-icon-trash
                                v-b-tooltip.hover
                                :title="$t('form.delete')"
                                scale="1.2"
                                style="cursor: pointer;"
                                variant="danger"
                                @click="rowDelete(row.index)"
                            ></b-icon-trash>
                        </div>
                    </template>
                </b-table>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
export default {
    name: "ModalFormSeason",
    components: {},
    props: {
        componentKey: {
            type: String,
            default: ''
        },
        parentRecords: {
            type: Array,
            default() {
                return []
            }
        },
        modalBus: Object,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            table: {
                items: [],
                fields: [],
                columns: [
                    {
                        key: 'label',
                        label: this.$t('form.label'),
                        sortable: false,
                    },
                    {
                        key: 'start',
                        label: this.$t('adventure.season_start'),
                        sortable: false,
                    },
                    {
                        key: 'end',
                        label: this.$t('adventure.season_end'),
                        sortable: false,
                    },
                    {
                        key: 'action',
                        label: '',
                        id: 0,
                        sortable: false,
                        thStyle: {width: '60px'}    // 90px for 3 icons
                    },
                ],
                sortBy: 'id',
                sortDesc: false,
            },
            records: [],
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            this.records = this.__clone(this.parentRecords);
            this.modalOpen();
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'tabValidity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        // <-- modal -->
        modalOpen() {
            /*            this.resetValidity();
                        this.resetErrors();
                        // <--  -->
                        if (this.form.id > 0) {
                            this.loadEdit();
                            return;
                        }
                        this.loadNew();*/
            this.load();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name + this.componentKey);
            });
        },
        // <-- table -->
        rowAdd() {
            let index = this.records.length;
            this.records.push(
                {
                    key: this.__createUniqueKey(index),
                    label: null,
                    start: null,
                    end: null,
                }
            );
        },
        rowDelete(index) {
            this.records.splice(index, 1);
        },
        // <-- form -->
        load() {
            // this.showOverlay(true);
            // // <-- api -->
            // const config = {
            //     headers: this.__getHeaders()
            // };
            // // <-- axios -->
            // this.axios.get(this.uri.main + `/${this.form.id}`, config)
            //     .then((response) => {
            //         // <-- data -->
            //         this.$set(this, 'data', response.data.data);
            //         // <-- record -->
            //         let recordDefault = this.__clone(this.recordDefault);
            //
            //         let record = response.data.record;
            //         this.__mergeObjectDeep(recordDefault, record);
            //         this.$set(this, 'record', record);
            //         // <--  -->
            this.$bvModal.show(this.$options.name + this.componentKey);
            //         this.showOverlay(false);
            //     })
            //     .catch((error) => {
            //         console.log("error:", error.data);
            //     });
        },
        formSave(bvModalEvent) {
            let array = [];
            array = array.concat(this.getRecordDefault, this.records);
            this.$emit('eventUpdateSeasons', array);
        },
    },
    computed: {
        title: {
            get() {
                // let record = this.record;
                // if (this.form.id <= 0 || record === null || record.meta === undefined || record.meta.name === undefined) {
                //     return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                // }
                // // <-- name -->
                // let name = record.meta.name;
                // // <--  -->
                // return this.$tc(this.localeKey, 0) + ': ' + name;
                return this.$tc('adventure.season', 1);
            },
        },
        // <-- data -->
        getRecordDefault() {
            let object = null;
            // <--  -->
            this.parentRecords.every((v, index) => {
                if (v.key === 'default') {
                    object = {
                        label: this.$t(`form.default`),
                        key: v.key,
                        description: this.$t('adventure.season_default_description'),
                    }
                    return false;
                }
                return true;
            });
            // <--  -->
            return object;
        },
        getRecords() {
            let array = this.records;
            // <--  -->
            array.every((v, index) => {
                if (v.key === 'default') {
                    array.splice(index, 1);
                    return false;
                }
                return true;
            });
            // <--  -->
            return array;
        },
        // <-- validity -->
        dateStartValidity() {
            return null;
        },
        dateEndValidity() {
            return null;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
