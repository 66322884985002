<template>
    <b-card class="experience-task-step mb-2 p-2"
            :header-class="index % 2 === 0 ?'card-header-light' : 'card-header-dark'"
    >
        <!-- header -->
        <template #header>
            <b-row class="d-flex align-middle">
                <b-col class="draggable-handle_task p-1">
                    <span>{{index + 1}}</span>
                    <span class="ml-2"
                          :style="__isNull(record.unique.title) ? 'opacity: 20%' : 'font-weight: bolder'">{{getRecordTitle}}</span>
                </b-col>
                <!-- button: collapse -->
                <b-button v-if="isCollapse" class="px-2 py-0 mr-1 button-header" @click="setCollapse(false)">_
                </b-button>
                <!-- button: expand -->
                <b-button v-if="!isCollapse" class="px-1 py-0 mr-1 button-header" @click="setCollapse(true)">
                    <b-icon-app
                        scale="0.7"
                    ></b-icon-app>
                </b-button>
                <!-- button: close -->
                <b-button class="px-2 py-0 button-header"
                          @click="$emit('eventRemoveStep', {locale: locale, key: record.together.key})">x
                </b-button>
            </b-row>
        </template>
        <!-- body -->
        <template #default v-if="isCollapse">
            <!-- title -->
            <b-row class="mt-2">
                <b-col cols="6">
                    <b-form-group
                        class="mb-2 mr-sm-02 mb-sm-0"
                        label-class="mb-0"
                        :label="$t('form.title')"
                        label-for="task.title_id"
                        description=""
                    >
                        <b-input-group>
                            <b-form-input
                                id="task.title_id"
                                v-model="record.unique.title"
                            ></b-form-input>
                            <b-button v-if="isLanguageBasis" v-b-toggle.languageBasisTitle>{{languageBasis}}</b-button>
                        </b-input-group>
                        <!-- language basis -->
                        <b-collapse v-if="isLanguageBasis && languageBasisRecord !== undefined" id="languageBasisTitle">
                            <b-card class="language-basis pl-2">
                                <span v-html="getLanguageBasisRecord.title"></span>
                            </b-card>
                        </b-collapse>
                    </b-form-group>
                </b-col>
            </b-row>
            <!-- description -->
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-form-group
                        class="mb-2 mr-sm-02 mb-sm-0"
                        label-class="mb-0"
                        :label="$t('form.description')"
                        label-for="task.description"
                        description=""
                    >
                        <b-input-group>
                            <b-form-textarea
                                id="task.description"
                                v-model="record.unique.description"
                            ></b-form-textarea>
                            <b-button v-if="isLanguageBasis" v-b-toggle.languageBasisDescription
                                      style="max-height: 40px">{{languageBasis}}
                            </b-button>
                        </b-input-group>
                        <!-- language basis -->
                        <b-collapse v-if="isLanguageBasis && languageBasisRecord !== undefined"
                                    id="languageBasisDescription">
                            <b-card class="language-basis pl-2">
                                <span v-html="getLanguageBasisRecord.description"></span>
                            </b-card>
                        </b-collapse>
                    </b-form-group>
                </b-col>
            </b-row>
            <!-- question -->
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-form-group
                        class="mb-2 mr-sm-02 mb-sm-0"
                        label-class="mb-0"
                        :label="$t('adventure.question')"
                        label-for="task.question"
                        description=""
                    >
                        <b-input-group>
                            <b-form-textarea
                                id="task.question"
                                v-model="record.unique.question"
                            ></b-form-textarea>
                            <b-button v-if="isLanguageBasis" v-b-toggle.languageBasisQuestion style="max-height: 40px">
                                {{languageBasis}}
                            </b-button>
                        </b-input-group>
                        <!-- language basis -->
                        <b-collapse v-if="isLanguageBasis && languageBasisRecord !== undefined"
                                    id="languageBasisQuestion">
                            <b-card class="language-basis pl-2">
                                <span v-html="getLanguageBasisRecord.question"></span>
                            </b-card>
                        </b-collapse>
                    </b-form-group>
                </b-col>
            </b-row>
            <!-- answers -->
            <b-row class="mt-2">
                <b-col cols="12">
                    <!-- button: add answer, random -->
                    <b-row class="d-flex align-items-center mx-0">
                        <b-col class="p-1">
                            <span class="font-weight-bolder">{{$tc('adventure.answer', 1)}}:</span>
                        </b-col>
                        <b-checkbox v-model="record.together.answersRandom">{{$t('adventure.random_answers')}}
                        </b-checkbox>
                        <b-button class="ml-2" size="sm" variant="info" @click="addAnswer()">
                            <b-icon-plus
                                scale="1.5"
                            ></b-icon-plus>
                        </b-button>
                    </b-row>

                    <!-- answers -->
                    <draggable
                        v-model="record.unique.answers"
                        handle=".draggable-handle_answer"
                        @start="drag=true"
                        @end="drag=false"
                        @change="syncAnswer()"
                    >

                        <div v-for="(answer, answerIndex) in record.unique.answers">
                            <b-row class="mx-0 mt-2 d-flex justify-content-between">
                                <!-- answer index -->
                                <div class="d-flex align-items-center justify-content-center border" style="width: 3%">
                                    {{answerIndex + 1}}.
                                </div>
                                <!-- move button -->
                                <b-button class="draggable-handle_answer" size="sm" variant="info">
                                    <b-icon-arrows-move></b-icon-arrows-move>
                                </b-button>

                                <!-- answer -->
                                <b-input-group style="max-width: 77%">
                                    <b-form-input
                                        :placeholder="$tc('adventure.answer', 0)"
                                        v-model="answer.text"
                                    ></b-form-input>
                                    <b-button v-if="isLanguageBasis"
                                              v-b-toggle="answer.key"
                                              @click="setLanguageBasisAnswers(answer.key)"
                                    >{{languageBasis}}
                                    </b-button>
                                </b-input-group>
                                <!-- points -->
                                <b-form-input style="width: 10%"
                                              :placeholder="$t('adventure.point')"
                                              v-model="answer.points"
                                              type="number"
                                              min="0"
                                              @keypress="__onlyIntegerKey($event);"
                                              @update="syncAnswer()"
                                ></b-form-input>
                                <!-- button delete -->
                                <b-button variant="info" size="sm">
                                    <b-icon-trash
                                        :title="$t('form.delete')"
                                        scale="1"
                                        style="cursor: pointer;"
                                        variant="danger"
                                        @click="removeAnswer(answer.key)"
                                    ></b-icon-trash>
                                </b-button>
                            </b-row>
                            <!-- language basis -->
                            <div v-if="isLanguageBasis && languageBasisRecord !== undefined"
                                        v-show="languageBasisAnswers[answer.key]"
                                        style="padding-left: 8%; padding-right: 15%"
                            >
                                <b-card class="language-basis pl-2">
                                    <span v-html="getLanguageBasisRecord.answers[answerIndex].text"></span>
                                </b-card>
                            </div>
                        </div>

                    </draggable>
                </b-col>
            </b-row>
        </template>
    </b-card>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: "ElementExperienceTaskStepQuestion",
    components: {draggable},
    props: {
        locale: null,
        index: null,
        record: null,
        json_key: {
          type: String,
          default: 'tasks'
        },
        collapseTasks: {
            type: Object,
            default() {
                return {}
            }
        },
        languageBasisAnswers: {
            type: Object,
            default() {
                return {}
            }
        },
        languageBasis: {
            type: String,
            default: null
        },
        languageBasisRecord: null
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {
        addAnswer() {
            let data = {
                locale: this.locale,
                stepKey: this.record.together.key,
            };
            // <--  -->
            this.$emit('eventAddAnswer', data);
        },
        removeAnswer(answerKey) {
            let data = {
                locale: this.locale,
                stepKey: this.record.together.key,
                answerKey: answerKey
            };
            // <--  -->
            this.$emit('eventRemoveAnswer', data);
        },
        syncAnswer() {
            let data = {
                locale: this.locale,
                stepKey: this.record.together.key,
            };
            // <--  -->
            this.$emit('eventSyncAnswer', data);
        },
        // <--  -->
        setCollapse(collapse) {
            let stepKey = this.record.together.key;
            this.$set(this.collapseTasks, stepKey, collapse);
        },
        setLanguageBasisAnswers(answerKey) {
            let stepKey = this.record.together.key;
            let old = this.languageBasisAnswers[answerKey];
            this.$set(this.languageBasisAnswers, answerKey, old ? false : true);
        }
    },
    computed: {
        getRecordTitle(answerKey) {
            return this.__isNull(this.record.unique.title) ? this.$t('adventure.title_task') : this.record.unique.title;
        },
        isCollapse() {
            let stepKey = this.record.together.key;
            return this.collapseTasks[stepKey];
        },
        isLanguageBasis() {
            return this.languageBasis !== null && this.languageBasis !== this.locale;
        },
        getLanguageBasisRecord() {
            return this.languageBasisRecord[this.json_key][this.index].unique;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.draggable-handle_task {
    cursor: move;
}

.draggable-handle_answer {
    cursor: move;
}

.card-body {
    padding: 0;
}

.experience-task-step {
    /*height: 100px;*/
    width: 100%;
    border: lightgrey 1px solid;
}

.button-header {
    background: grey;
    border: none;
}

.button-header:hover {
    color: white;
    background: #7C9848;
    border: none;
}

.button-header:focus {
    box-shadow: none;
}

.btn:focus {
    /*background: red;*/
    box-shadow: none;
}

.card-header-light {
    background: #dcdcdc;
}


.card-header-dark {
    background: #c0c0c0;
}

.language-basis {
    min-height: 25px;
    background: #dcdcdc;
    color: white;
}

</style>
