<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        scrollable
        size="xl"
        @ok="formSave"
    >
        manjka podatek: <!-- // TODO Missing data!!! -->
        <br>- request_confirm; experience zahteva najavo, da ti lahko potrdi termin, število obiskovalcev...
        <br>- accessibility: {private, public, agency...}
        <br>- tasks: dodaj namig (tip)
        <br>- cenik: lahko je tudi: brezplačno, ni podatka o cenah

        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab destinations -->
                        <b-tab :active="record.destination_id === null">
                            <template #title>
                                <b-icon v-if="tabDestinationValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$tc('adventure.destination.main', 0)}}
                            </template>
                            <b-card-text>
                                <b-row>
                                    <b-col>
                                        <!-- destination_id -->
                                        <b-form-group
                                            :label="$tc('adventure.destination.main', 0)"
                                            class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                            description=""
                                            label-class="mb-0"
                                            label-for="destination_id"
                                        >
                                            <b-form-select
                                                id="destination_id"
                                                v-model="record.destination_id"
                                                :options="getDestinations"
                                                :state="destinationIdValidity"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                required
                                            ></b-form-select>
                                            <b-form-invalid-feedback :state="destinationIdValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- tab basic -->
                        <b-tab :active="record.id > 0">
                            <template #title>
                                <b-icon v-if="tabBasicValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('form.tab.basic')}}
                            </template>
                            <b-card-text>
                                <!-- meta -->
                                <b-row>
                                    <!-- meta label -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$tc('form.meta.data', 0)"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <!-- meta name -->
                                        <b-form-group
                                            :label="$t('adventure.title') + '*'"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            description=""
                                            label-class="mb-0"
                                            label-for="meta_name"
                                        >
                                            <b-form-input
                                                id="meta_name"
                                                v-model="record.meta.name"
                                                :state="nameValidity"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                type="text"
                                            ></b-form-input>
                                            <b-form-invalid-feedback :state="nameValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        <!-- basic data -->
                                        <b-form-group
                                            class="mb-2"
                                            label=""
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <!-- meta name -->
                                        <b-form-group
                                            :label="$t('form.subtitle')"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            description=""
                                            label-class="mb-0"
                                            label-for="meta_subtitle"
                                        >
                                            <b-form-input
                                                id="meta_subtitle"
                                                v-model="record.meta.subtitle"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                type="text"
                                            ></b-form-input>
                                        </b-form-group>
                                        <!-- basic data -->
                                        <b-form-group
                                            class="mb-2"
                                            label=""
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!-- content -->
                                <b-row>
                                    <!-- label content -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('form.content')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <!-- experience_category -->
                                    <b-col cols="6">
                                        <b-form-group
                                            class="mb-2 mr-sm-02 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$tc('adventure.experience_category.main', 0) + '/' + $tc('adventure.experience_type.main', 0)"
                                            label-for="exCategories_id"
                                            description=""
                                        >
                                            <treeselect
                                                :multiple="true"
                                                v-model="record.content.experienceTypeIds"
                                                :options="getExperienceCategoriesTypes"
                                                :noOptionsText="$t('form.no_options')"
                                                :noChildrenText="$t('form.no_children')"
                                                :noresultText="$t('form.no_result')"
                                                :disable-branch-nodes="true"
                                                search-nested
                                            />
                                            <b-form-invalid-feedback :state="experienceTypeIdsValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    <!-- order -->
                                    <b-col cols="6">
                                        <b-form-group
                                            id="group-order"
                                            class="mb-2 mr-sm-02 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$t('form.order')"
                                            label-for="order"
                                            description=""
                                        >
                                            <b-form-input
                                                id="order"
                                                type="number"
                                                v-model="record.order"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <!-- languages tour -->
                                    <b-col cols="6">
                                        <b-form-group
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$tc('adventure.tour_language', 1) + '*'"
                                            label-for="languageId"
                                            description=""
                                            :state="languageTourIdsValidity"
                                        >
                                            <b-form-select
                                                id="languageId"
                                                v-model="languageId"
                                                :options="getLanguages"
                                                required
                                                :state="languageTourIdsValidity"
                                            >
                                            </b-form-select>
                                            <b-form-invalid-feedback :state="languageTourIdsValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                            <!-- selected languageTour -->
                                            <div class="mt-1">
                                                <b-button
                                                    v-for="language in getLanguageTourIdsSelected"
                                                    pill
                                                    class="mr-1 mt-1 remove"
                                                    variant="primary"
                                                    :title="$t('form.remove')"
                                                    size="sm"
                                                    @click="removeSelectedLanguage(language.value)"
                                                >{{language.text}}
                                                </b-button>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                            <!-- basic locale -->
                            <form-experience-locale-basic
                                :title="$t('form.basic.data')"
                                :data_locale="data.locale"
                                :record="record.locale"
                            ></form-experience-locale-basic>
                        </b-tab>
                        <!-- tab detail -->
                        <b-tab>
                            <template #title>
                                <b-icon v-if="tabDetailValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('adventure.experience.tab.details')}}
                            </template>
                            <b-card-text>
                                <b-row>
                                    <b-col cols="6">
                                        <!-- participants number -->
                                        <b-form-group
                                            :label="$t('adventure.participants_number') + '*'"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            description=""
                                            label-class="mb-0"
                                            label-for="meta_participants_number"
                                        >
                                            <div
                                                id="meta_participants_number"
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <b-form-input
                                                    v-model="record.content.participants_min"
                                                    type="number"
                                                    placeholder="min"
                                                    :min="1"
                                                    @keypress="__onlyIntegerKey($event)"
                                                    :state="participantsNumberValidity"
                                                ></b-form-input>
                                                <div class="mx-1">-</div>
                                                <b-form-input
                                                    v-model="record.content.participants_max"
                                                    type="number"
                                                    placeholder="max"
                                                    :min="record.content.participants_min"
                                                    @keypress="__onlyIntegerKey($event)"
                                                    :state="participantsNumberValidity"
                                                ></b-form-input>
                                            </div>
                                            <b-form-invalid-feedback :state="participantsNumberValidity">
                                                {{$t('form.required_field')}}   <!-- // TODO change message -->
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <!-- suitable for -->
                                        <b-form-group
                                            class="mb-2 mr-sm-02 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$t('adventure.suitable_for') + '*'"
                                            label-for="suitable_for"
                                            description=""
                                            :state="suitableForValidity"
                                        >
                                            <b-form-select
                                                id="suitable_for"
                                                v-model="suitableId"
                                                :options="getSuitable"
                                                required
                                                :state="suitableForValidity"
                                            >
                                            </b-form-select>
                                            <b-form-invalid-feedback :state="suitableForValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                            <!-- selected -->
                                            <div class="mt-1">
                                                <b-button
                                                    v-for="suitable in getSuitableIdsSelected"
                                                    pill
                                                    class="mr-1 mt-1 remove"
                                                    variant="primary"
                                                    :title="$t('form.remove')"
                                                    size="sm"
                                                    @click="removeSelectedSuitable(suitable.id)"
                                                >{{suitable.text}}
                                                </b-button>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <!-- season -->
                                    <b-col cols="6">
                                        <b-form-group
                                            :label="$tc('adventure.season', 0)"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            description=""
                                            label-class="mb-0"
                                            label-for="content-available"
                                        >
                                            <div
                                                id="content-available"
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <!-- season start -->
                                                <b-form-datepicker
                                                    v-model="record.content.season.start"
                                                    :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                                                    :start-weekday="__localeContent.firstDayOfWeek"
                                                    :locale="__getVueLocale()"
                                                    label-help=""
                                                    label-no-date-selected=""
                                                    close-button
                                                    :label-close-button="$t('form.datepicker.close')"
                                                    reset-button
                                                    reset
                                                    :label-reset-button="$t('form.datepicker.reset')"
                                                ></b-form-datepicker>
                                                <div class="mx-1">-</div>
                                                <!-- season end -->
                                                <b-form-datepicker
                                                    v-model="record.content.season.end"
                                                    :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                                                    :start-weekday="__localeContent.firstDayOfWeek"
                                                    :locale="__getVueLocale()"
                                                    :min="record.content.season.start"
                                                    label-help=""
                                                    label-no-date-selected=""
                                                    close-button
                                                    :label-close-button="$t('form.datepicker.close')"
                                                    reset-button
                                                    reset
                                                    :label-reset-button="$t('form.datepicker.reset')"
                                                ></b-form-datepicker>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                    <!-- duration -->
                                    <b-col cols="6">
                                        <b-form-group
                                            :label="$t('adventure.duration') + '*'"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            description=""
                                            label-class="mb-0"
                                            label-for="duration"
                                        >
                                            <div
                                                id="duration"
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <!-- duration day -->
                                                <b-input-group :append="$tc('date_time.day', 0)">
                                                    <b-form-spinbutton
                                                        v-model="record.content.duration.days"
                                                        min="0"
                                                    ></b-form-spinbutton>

                                                </b-input-group>
                                                <!-- duration time -->
                                                <b-input-group :append="$t('date_time.hour_min')">
                                                    <b-form-input
                                                        class="ml-2"
                                                        v-model="record.content.duration.time"
                                                        type="time"
                                                        style="width: 90px"
                                                    ></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <b-form-invalid-feedback :state="durationValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                            <!-- detail locale -->
                            <form-experience-locale-detail
                                :title="$t('form.detail')"
                                :data_locale="data.locale"
                                :record="record.locale"
                            ></form-experience-locale-detail>
                        </b-tab>
                        <!-- tab story -->
                        <b-tab :title="$t('adventure.experience.tab.story')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$t('adventure.story')"
                                            :record="record.locale"
                                            color_class="editor-green"
                                            json_key="story"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- mission -->
                        <b-tab :title="$t('adventure.mission')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$t('adventure.mission')"
                                            :record="record.locale"
                                            color_class="editor-yellow"
                                            json_key="mission"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab: opening hours  -->
                        <b-tab :title="$t('adventure.experience.tab.opening_hours')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body style="overflow-x: auto">
                                        <element-opening-hours
                                            :title="null"
                                            :data-price-list-seasons="record.content.priceListSeasons"
                                            :parent-record="record.content.openingHours"
                                            @eventUpdatePriceListSeasons="updatePriceListSeasons"
                                        ></element-opening-hours>
                                    </b-card>
                                </b-form-group>
                                <b-row class="mt-2">
                                    <b-col cols="6">
                                        <!-- non-working days -->
                                        <b-form-group
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$t('date_time.non_working_days')"
                                            label-for="non_working_days_id"
                                            description=""
                                        >
                                            <b-form-datepicker
                                                v-model="nonWorkingDay"
                                                :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                                                :start-weekday="__localeContent.firstDayOfWeek"
                                                :locale="__getVueLocale()"
                                                label-help=""
                                                label-no-date-selected=""
                                                min=""
                                            ></b-form-datepicker>
                                            <!-- non_workingDays -->
                                            <div class="mt-1">
                                                <b-button
                                                    v-for="nonWorkingDay in record.content.nonWorkingDays"
                                                    pill
                                                    class="mr-1 mt-1 remove"
                                                    variant="primary"
                                                    :title="$t('form.remove')"
                                                    size="sm"
                                                    @click="removeNonWorkingDay(nonWorkingDay)"
                                                >
                                                    {{nonWorkingDay | baseDateToLocaleDate(__getVueLocale())}}
                                                </b-button>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- tab price list -->
                        <b-tab :title="$t('adventure.experience.tab.price_list')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body style="overflow-x: auto">
                                        <element-price-list
                                            :title="null"
                                            :data-currencies="data.currencies"
                                            :data-price-units="data.priceUnits"
                                            :record-price-list-seasons="record.content.priceListSeasons"
                                            :record-price-list="record.content.priceList"
                                            :record-currency-id="record.currency_id"
                                            @eventSetCurrencyId="setCurrencyId"
                                            @eventUpdatePriceListSeasons="updatePriceListSeasons"
                                        ></element-price-list>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab tasks -->
                        <b-tab :title="$t('adventure.experience.tab.tasks')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <form-experience-locale-task
                                            :title="null"
                                            :data_locale="data.locale"
                                            :record="record.locale"
                                        ></form-experience-locale-task>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab condition -->
                        <b-tab :title="$t('adventure.experience.tab.conditions')">
                            <b-card-text>
                                <b-row>
                                    <!-- age limits -->
                                    <b-col cols="6">
                                        <b-form-group
                                            :label="$t('adventure.age_limits')"
                                            label-size="md"
                                            label-class="font-weight-bold pt-0"
                                            class="mb-0"
                                        >
                                            <div
                                                id="age_limits"
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <!-- age_limits: min -->
                                                <b-form-group
                                                    :label="$t('adventure.min_age')"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    description=""
                                                    label-class="mb-0"
                                                    label-for="ageLimits_min"
                                                >
                                                    <b-form-input
                                                        id="ageLimits_min"
                                                        v-model="record.content.conditions.minAge"
                                                        type="number"
                                                        @keypress="__onlyIntegerKey($event)"
                                                    ></b-form-input>
                                                </b-form-group>
                                                <!-- age_limits: max -->
                                                <b-form-group
                                                    :label="$t('adventure.max_age')"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    description=""
                                                    label-class="mb-0"
                                                    label-for="ageLimits_max"
                                                >
                                                    <b-form-input
                                                        id="ageLimits_max"
                                                        v-model="record.content.conditions.maxAge"
                                                        type="number"
                                                        @keypress="__onlyIntegerKey($event)"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-form-group
                                        label="Drugi pogoji"
                                        class="mb-0 mt-2 group-title p-0 mx-3"
                                        label-align-sm="left"
                                        label-class="font-weight-bold"
                                        label-cols-sm="12"
                                        label-size=""
                                    >
                                        <b-card no-body>
                                            <tab-locale-editor
                                                :data_locale="data.locale"
                                                :label="$tc('adventure.condition', 1)"
                                                :record="record.locale"
                                                json_key="conditionNotes"
                                            ></tab-locale-editor>
                                        </b-card>
                                    </b-form-group>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- pictures -->
                        <b-tab :title="$t('form.tab.picture')">
                            <PictureManagerDeprecated
                                :picture-manager-bus="pictureManagerBus"
                                @eventUpdate="eventPictureManagerUpdate"
                            >
                            </PictureManagerDeprecated>
                        </b-tab>
                        <!-- notes -->
                        <b-tab :title="$t('form.tab.notes')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$tc('form.notes', 2)"
                                            :record="record.locale"
                                            json_key="notes"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import TabLocaleEditor from "@/components/general/elements/tabAccessories/TabLocaleEditor";
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";
import FormExperienceLocaleBasic from "@/components/adventure/experience/FormExperienceLocaleBasic";
import FormExperienceLocaleDetail from "@/components/adventure/experience/FormExperienceLocaleDetail";
import FormExTypeLocale from "@/components/adventure/type/FormExTypeLocale";
import FormExperienceLocaleCondition from "@/components/adventure/experience/FormExperienceLocaleCondition";
import FormExperienceLocaleTask from "@/components/adventure/experience/FormExperienceLocaleTask";
import FormExperienceLocalePriceList from "@/components/adventure/experience/FormExperienceLocalePriceList";
import ElementPriceList from "@/components/adventure/experience/elements/ElementPriceList";
import PictureManagerDeprecated
    from "@/components/general/elements/pictures/pictures_manager_deprecated/PictureManagerDeprecated";
import Vue from "vue";
import ElementOpeningHours from "@/components/adventure/experience/elements/ElementOpeningHours";

//@group Form.ADVENTURE
export default {
    name: "ModalFormExperience",
    components: {
        Treeselect,
        ElementOpeningHours,
        PictureManagerDeprecated,
        ElementPriceList,
        FormExperienceLocalePriceList,
        FormExperienceLocaleTask,
        FormExperienceLocaleCondition,
        FormExTypeLocale,
        FormExperienceLocaleDetail,
        FormExperienceLocaleBasic,
        FormIdActive,
        TabLocaleEditor
    },
    props: {
        purposeTypeId: {
            type: Number,
            default: 0,
        },
        priceUnitType: {
            type: Number,
            default: 0,
        },
        // Unique key to define modal window
        localeKey: {
            type: String,
            default: null,
            required: true
        },
        // Uri for calling api
        uri: {
            type: Object,
            default: null,
            required: false
        },
        // Bus object for events
        modalBus: {
            type: Object,
            default() {
                return {}
            },
            required: false
        },
        // form: {},
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            data: {},
            division: 'experience',
            recordId: 0,
            recordDefault: {
                id: 0,
                destination_id: null,
                currency_id: 1,
                creator: null,
                active: false,
                order: 0,
                meta: {
                    name: null,
                },
                content: {
                    experienceTypeIds: [],
                    participants_min: 1,
                    participants_max: null,
                    isGuidedTour: true,
                    languageTourIds: [],
                    suitableIds: [],
                    duration: {
                        days: null,
                        time: null
                    },
                    priceListSeasons: [
                        {
                            label: this.$t('form.default'),
                            key: 'default',
                            start: null,
                            end: null,
                        },
                    ],
                    season: {
                        start: null,
                        end: null
                    },
                    priceList: [],
                    openingHours: {
                        type: null,
                        byDays: [],
                    },
                    nonWorkingDays: [],
                    conditions: {
                        minAge: null,
                        maxAge: null,
                    }
                },
                locale: {},
                // <-- pictures -->
                pictures_order: [],
                pictures: [],
            },
            record: null,
            foreignRecord: {
                experienceTypeIds: [],
            },
            // <-- validity -->
            validityDefault: {
                check: false,
            },
            tabValidity: {},
            // <-- errors -->
            errorsDefault: {
                message: null,
            },
            errors: {
                message: null
            },
            // <--  -->
            exCategoryId: null,
            exTypeId: null,
            exTypes: [],
            languageId: null,
            suitableId: null,
            pictureManagerBus: new Vue(),
            nonWorkingDay: null,
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            this.recordId = recordId;
            this.modalOpen();
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'tabValidity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        // <-- modal -->
        modalOpen() {
            this.resetValidity();
            this.resetErrors();
            // <--  -->
            if (this.recordId > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <-- languages -->
        addSelectedLanguage(language) {
            this.record.content.isGuidedTour = true;
            if (language === null) {
                return;
            }
            // <-- no guided tour -->
            if (language === 0) {
                this.record.content.isGuidedTour = false,
                    this.record.content.languageTourIds = [];
                return;
            }
            // <--  -->
            let selectedLanguages = this.record.content.languageTourIds;
            // <-- check if exists -->
            let exists = false;
            selectedLanguages.every(v => {
                if (language !== v.id) {
                    return false;
                }
                exists = true;
                return true;
            });
            // <--  -->
            if (!exists) {
                this.record.content.languageTourIds.push(language);
            }
        },
        removeSelectedLanguage(languageId) {
            let selectedLanguages = this.record.content.languageTourIds;
            // <--  -->
            selectedLanguages.every((v, index) => {
                if (languageId === v) {
                    selectedLanguages.splice(index, 1);
                    return false;
                }
                return true;
            });
        },
        // <-- suitable -->
        addSelectedSuitable(suitable) {
            if (suitable === null) {
                return;
            }
            // <--  -->
            let selectedIds = this.record.content.suitableIds;
            // <-- check if exists -->
            let exists = false;
            selectedIds.every(v => {
                if (suitable !== v.id) {
                    return false;
                }
                exists = true;
                return true;
            });
            // <--  -->
            if (!exists) {
                this.record.content.suitableIds.push(suitable);
            }
        },
        removeSelectedSuitable(suitableId) {
            let selected = this.record.content.suitableIds;
            // <--  -->
            selected.every((v, index) => {
                if (suitableId === v.id) {
                    selected.splice(index, 1);
                    return false;
                }
                return true;
            });
        },
        // <-- events pictureManager -->
        eventPictureManagerUpdate(data) {
            this.$set(this.record, 'pictures_order', data.pictures_order);
            this.$set(this.record, 'pictures', data.pictures);
        },
        // <-- price list -->
        setCurrencyId(value) {
            this.$set(this.record, 'currency_id', value);
        },
        updatePriceListSeasons(data) {
            this.$set(this.record.content, 'priceListSeasons', data);
        },
        // <-- non-working days -->
        addNonWorkingDay(nonWorkingDay) {
            this.record.content.nonWorkingDays.push(nonWorkingDay);
        },
        removeNonWorkingDay(nonWorkingDay) {
            this.record.content.nonWorkingDays.every((day, index) => {
                if (day === nonWorkingDay) {
                    this.record.content.nonWorkingDays.splice(index, 1);
                    return false;
                }
                return true;
            });
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            // <-- record -->
            // this.$nextTick(() => {
            this.exCategoryId = null;
            this.exTypeId = null;
            this.exTypes = [];
            this.languages = [];
            this.$set(this, 'record', this.__clone(this.recordDefault));
            let id = 0;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignDataActive/${this.purposeTypeId}/${this.priceUnitType}`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.$bvModal.show(this.$options.name);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/showExtended/${this.recordId}/${this.purposeTypeId}/${this.priceUnitType}`, config)
                .then((response) => {
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // <-- record -->
                    let record = response.data.record;
                    this.languageId = record.content.isGuidedTour === false ? 0 : this.languageId;

                    this.$set(this, 'record', record);

                    this.$bvModal.show(this.$options.name);
                    // <-- pictures -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures)
                    });
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    console.log("error:", error.data);
                });
        },
        // <-- form -->
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            // <--  -->
            this.tabValidity.check = true;
            let validity = true;
            // <-- check -->
            this.$nextTick(() => {
                Object.keys(this.tabValidity).forEach(key => {
                    if (key !== 'check' && !this.tabValidity[key]) {
                        validity = false;
                    }
                });
                // <-- save -->
                if (validity) {
                    if (this.record.id > 0) {
                        this.baseUpdateFormData();
                        return;
                    }
                    this.baseInsertFormData();
                }
            });
        },
        // <-- base -->
        baseInsertFormData() {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            };
            // <-- form data -->
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    formData.append('division', this.division);
                    formData.append('files[' + index + ']', picture.file);
                    formData.append('keys[' + index + ']', picture.key);
                });
            }
            // <-- prepare data for axios -->
            formData.append('record', JSON.stringify(this.record));
            formData.append('foreignRecord', JSON.stringify(this.foreignRecord));
            // <-- axios -->
            // this.axios.post(this.uri.uri1 + '/form_data_insert', formData, config)
            this.axios.post(this.uri.main, formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
        baseUpdateFormData() {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            };
            // <-- form data -->
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    formData.append('division', this.division);
                    formData.append('files[' + index + ']', picture.file);
                    formData.append('keys[' + index + ']', picture.key);
                });
            }
            // <-- prepare data for axios -->
            formData.append('record', JSON.stringify(this.record));
            formData.append('foreignRecord', JSON.stringify(this.foreignRecord));
            // <-- axios -->
            this.axios.post(this.uri.uri1 + '/form_data_update' + `/${this.record.id}`, formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {
        title: {
            get() {
                let record = this.record;
                if (this.recordId <= 0 || record === null || record.meta === undefined || record.meta.name === undefined) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- name -->
                let name = record.meta.name;
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + name;
            },
        },
        // <-- data -->
        getDestinations() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.destinations)) {
                return array;
            }
            // <--  -->
            let name;
            this.data.destinations.forEach(item => {
                // <-- name -->
                name = `(${item.meta.name})`;
                if (!this.__isNull(item.locale.name) && !this.__isNull(item.locale)) {
                    name = item.locale.name;
                }
                // <--  -->
                array.push({
                    text: name,
                    value: item.id
                });
            });
            // <--  -->
            return array;
        },
        // <-- experienceTypes -->
        getExperienceCategoriesTypes() {
            if (this.data.experienceCategoriesTypes === undefined) {
                return [];
            }
            // <--  -->
            let array = [];
            let children = [];
            let label, childLabel;
            this.data.experienceCategoriesTypes.forEach(item => {
                // <-- name -->
                label = `(${item.meta.name})`;
                if (!this.__isNull(item.locale.name) && !this.__isNull(item.locale)) {
                    label = item.locale.name;
                }
                // <-- children -->
                item.experience_types.forEach(child => {
                    childLabel = `(${child.meta.name})`;
                    if (!this.__isNull(child.locale.name) && !this.__isNull(child.locale)) {
                        childLabel = child.locale.name;
                    }
                    children.push({
                        id: 'c' + child.id,
                        label: childLabel,
                    });
                });
                // <--  -->
                array.push({
                    id: item.id,
                    label: label,
                    children: children
                });
                children = [];
            });
            // <--  -->
            return array;
        },
        // <-- languageTour -->
        getLanguages() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.languages)) {
                return [
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: {id: null, text: null}
                    }
                ];
            }
            // <--  -->
            let items = this.data.languages;
            items = typeof items === 'object' ? Object.values(items) : items;

            let text = null;
            items.forEach(item => {
                text = item.language;
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- sort -->
            // array = array.sort(function (a, b) {
            //     let fa = a.text.toLowerCase(),
            //         fb = b.text.toLowerCase();
            //
            //     if (fa < fb) {
            //         return -1;
            //     }
            //     if (fa > fb) {
            //         return 1;
            //     }
            //     return 0;
            // });
            // <-- add placeholder on top -->
            array.unshift({
                text: this.$t('adventure.no_guided_tour'),
                value: 0,
            });
            array.unshift({
                text: this.$t('form.choose_placeholder'),
                value: null
            });
            // <--  -->
            return array;
        },
        getLanguageTourIdsSelected() {
            let array = [];
            this.record.content.languageTourIds.forEach(id => {
                this.getLanguages.every(v => {
                    if (v.value === id) {
                        array.push(v);
                        return false;
                    }
                    return true;
                });
            });
            return array;
        },
        // <-- suitable -->
        getSuitable() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.suitable)) {
                return [
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    }
                ];
            }
            // <--  -->
            let items = this.data.suitable;

            let text = null;
            items.forEach(item => {
                text = `(${item.meta.name})`;
                // <-- locale -->
                if (!this.__isNull(item.locale.name) && !this.__isNull(item.locale)) {
                    text = item.locale.name;
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- sort -->
            array = array.sort(function (a, b) {
                let fa = a.text.toLowerCase(),
                    fb = b.text.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            // <-- add placeholder on top -->
            array.unshift({
                text: this.$t('form.choose_placeholder'),
                value: null
            });
            // <--  -->
            return array;
        },
        getSuitableIdsSelected() {
            let array = [];
            this.record.content.suitableIds.forEach(id => {
                this.getSuitable.every(v => {
                    if (v.value === id) {
                        array.push(v);
                        return false;
                    }
                    return true;
                });
            });
            return array;
        },
        // <-- alerts -->
        alertVisible() {
            if (this.record === null) {
                return false;
            }
            // <--  -->
            return this.record.tax_rate_id === null;
        },
        getErrorsMessage() {
            let message = '';
            if (this.record !== null && this.record.tax_rate_id === null) {
                message += this.$t('finance.tax_rate_not_selected');
            }
            // <--  -->
            return message;
        },
        // <-- validity destinations -->
        destinationIdValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.destination_id > 0;
            return validity ? null : false;
        },
        tabDestinationValidity() {
            let tabValidity = true;
            tabValidity = tabValidity === false || this.destinationIdValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tabDestination', tabValidity);
            return tabValidity
        },
        // <-- validity: tab basic -->
        nameValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.record.meta.name);
            return validity ? null : false;
        },
        experienceTypeIdsValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.content.experienceTypeIds.length > 0;
            return validity ? null : false;
        },
        languageTourIdsValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.content.isGuidedTour === false || this.record.content.languageTourIds.length > 0;
            return validity ? null : false;
        },
        tabBasicValidity() {
            let tabValidity = true;
            tabValidity = tabValidity === false || this.nameValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.experienceTypeIdsValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.languageTourIdsValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tabBasic', tabValidity);
            return tabValidity
        },
        // <-- validity: tab detail -->
        participantsNumberValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let value = this.record.content.participants_max;
            let validity = !this.__isNull(value) && value.length > 0;
            return validity ? null : false;
        },
        suitableForValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let value = this.record.content.suitableIds;
            let validity = !this.__isNull(value) && value.length > 0;
            return validity ? null : false;
        },
        durationValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <-- days -->
            let valueDays = this.record.content.duration.days;
            let validityDays = !this.__isNull(valueDays) && valueDays > 0;
            // <-- time -->
            let valueTime = this.record.content.duration.time;
            let validityTime = !this.__isNull(valueTime);
            // <--  -->
            return validityDays || validityTime ? null : false;
        },
        tabDetailValidity() {
            let tabValidity = true;
            tabValidity = tabValidity === false || this.participantsNumberValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.suitableForValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.durationValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tabDetail', tabValidity);
            return tabValidity
        },
    },
    filters: {},
    watch: {
        'languageId': {
            handler: function (value) {
                this.addSelectedLanguage(value);
                if (value !== 0) {
                    this.$nextTick(() => {
                        this.languageId = null;
                    });
                }
            },
            deep: true
        },
        'suitableId': {
            handler: function (value) {
                this.addSelectedSuitable(value);
                this.$nextTick(() => {
                    this.suitableId = null;
                });
            },
            deep: true
        },
        'nonWorkingDay': {
            handler: function (value) {
                this.addNonWorkingDay(value);
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.remove:hover {
    color: yellow;
    background: red;
    cursor: not-allowed;
    opacity: 40%;
}
</style>
