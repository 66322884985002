<template>
    <b-form-group
        :label="title"
        class="mb-0"
        label-class="font-weight-bold pt-0"
        label-size="md"
    >
        <b-card no-body>
            <b-tabs card pills>
                <b-tab v-for="locale in getDataLocale"
                       :active="locale.locale === $i18n.locale"
                       :title="locale.locale" class="border-danger"
                       style="background: white"
                >
                    <b-row>
                        <b-col class="p-0 pl-2 " cols="6">
                            <!-- included -->
                            <b-form-group
                                :label="$t('adventure.included')"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                description=""
                                label-class="mb-0"
                                :label-for="'title_' + locale.included"
                            >
                                <b-form-input
                                    :id="'title_' + locale.included"
                                    v-model.lazy="record[locale.locale].included"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-form-group>
</template>

<script>
export default {
    name: "FormExperienceLocaleDetail",
    components: {},
    props: {
        title: {
            type: String,
            default() {
                return this.$t('form.tab.locale');
            }
        },
        data_locale: {
            type: Array
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        validator(tag) {
            return tag.length > 2;
        },
    },
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {
                        duration: null,
                        included: null,
                    });
                }
            });
            // <--  -->
            return this.data_locale;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
