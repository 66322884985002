<template>
    <div v-if="record !== null">
        <!-- toolbar -->
        <b-button-toolbar class="d-flex justify-content-between m-2 p-0">
            <div class="d-flex justify-content-start">
                <!-- button: seasons -->
                <b-button size="sm" variant="secondary" @click="openModalSeason()">{{$tc('adventure.season'), 1}}
                </b-button>
                <!-- openingHoursType -->
                <b-form-select
                    v-model="record.type"
                    :options="getOpeningHoursType"
                    class="mb-2 ml-sm-l ml-1 mb-sm-0 bg-white"
                    size="sm"
                ></b-form-select>
            </div>
            <!--            &lt;!&ndash; button: add &ndash;&gt;
                        <b-button size="sm" class="ml-1" variant="secondary" @click="addRecord()">
                            <b-icon-plus
                                scale="2"
                                variant="white"
                            ></b-icon-plus>
                        </b-button>-->
        </b-button-toolbar>
        <!-- table -->
        <b-table-simple hover responsive striped sticky-header>
            <!-- head -->
            <b-thead head-variant="">
                <b-tr variant="dark">
                    <b-th v-for="(col, colIndex) in tableColumns" :class="col.class" :sticky-column="colIndex === 0">
                        {{col.label}}
                    </b-th>
                </b-tr>
            </b-thead>
            <!-- rows -->
            <b-tbody>
                <b-tr v-for="(row, rowIndex) in tableItems">
                    <b-td v-for="(col, colIndex) in tableColumns" class="p-2"
                          :sticky-column="colIndex === 0"
                          @click="tableClick($event, rowIndex, colIndex)"
                    >
                        <!-- dayOfWeekName -->
                        <div v-if="col.key==='dayOfWeek'">
                            {{row.dayOfWeekName}}
                        </div>
                        <!--  -->
                        <div v-else>
                            <!--                            <div>-->
                            <!--                                {{row.seasonHours[col.key]}}-->
                            <!--                            </div>-->
                            <div v-for="(session, index) in row.seasonHours[col.key]">
                                <!--                                {{session}}-->
                                <div class="d-flex justify-content-center align-items-center">
                                    <b-form-input v-model="session.from" type="time" size="sm"
                                                  style="width: 90px"
                                                  @click.stop=""
                                    ></b-form-input>
                                    -
                                    <b-form-input v-model="session.to" type="time" size="sm"
                                                  style="width: 90px"
                                                  @click.stop=""
                                    ></b-form-input>
                                    <!-- button remove session -->
                                    <b-button-close text-variant="danger"
                                                    @click.stop="sessionRemove(row.dayOfWeek, col.key, index)"
                                    ></b-button-close>
                                </div>
                            </div>
                            <!-- action -->
                            <div class="d-flex justify-content-center align-items-center mt-1">
                                <!-- button add -->
                                <b-button class="p-0" style="width: 23px; height: 23px;">
                                    <b-icon-plus
                                        :title="$t('form.add')"
                                        scale="1.1"
                                        @click.stop="sessionAdd(row.dayOfWeek, col.key)"
                                    ></b-icon-plus>
                                </b-button>
                            </div>
                        </div>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <!-- modalFormXxx -->
        <modal-form-season
            :component-key="componentKey"
            :modal-bus.sync="modalBus"
            :parent-records="getPriceListSeasons"
            @eventUpdateSeasons="updateSeasons"
        ></modal-form-season>
    </div>
</template>

<script>
import ModalFormSeason from "@/components/adventure/experience/elements/ModalFormSeason";
import Vue from "vue";

export default {
    name: "ElementOpeningHours",
    components: {ModalFormSeason},
    props: {
        title: {
            type: String,
            default() {
                return this.$t('form.tab.locale');
            }
        },
        dataPriceListSeasons: {
            type: Array,
            default() {
                return []
            }
        },
        parentRecord: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            componentKey: null,
            record: null,
            table: {
                items: [],
                fields: [],
                columns: [
                    {
                        key: 'dayOfWeek',
                        label: this.$tc('date_time.day', 0),
                        class: 'text-left'
                    },
                ],
            },
            // <-- modal season -->
            modalBus: new Vue(),
        }
    },
    mounted() {
        this.componentKey = this.__randomKey();
        this.record = this.parentRecord;
    },
    methods: {
        // <-- record -->
        fixRecordByDays() {
            let array = [];
            // <--  -->
            let dayExists = false;
            let seasonExists = false;
            let seasonHours = {};
            this.getDaysOfWeek.forEach(dayOfWeek => {
                // seasonHours = {};
                dayExists = false;
                this.record.byDays.forEach(record => {
                    // <-- day exists -->
                    if (record.dayOfWeek === dayOfWeek) {
                        seasonHours = {};
                        this.dataPriceListSeasons.forEach(season => {
                            seasonHours[season.key] = [{
                                from: null,
                                to: null
                            }];
                            Object.keys(record.seasonHours).every(recordKey => {
                                if (season.key === recordKey) {
                                    seasonHours[season.key] = record.seasonHours[season.key];
                                    return false;
                                }
                                return true;
                            });
                        });
                        array.push({
                            dayOfWeek: dayOfWeek,
                            seasonHours: seasonHours,
                        });
                        dayExists = true;
                    }
                });
                // <-- add missing day -->
                if (!dayExists) {
                    array.push({
                        dayOfWeek: dayOfWeek,
                        seasonHours: {
                            default: [
                                {
                                    from: null,
                                    to: null
                                }
                            ]
                        },
                    });
                }
            });
            // <--  -->
            this.recordFixed = true;
            this.record.byDays = array;
        },
        // <-- table -->
        tableClick(event, rowIndex, colIndex) {
            // if(event.ctrlKey)
            if (colIndex === 0) {
                return;
            }
            // console.log("click", rowIndex, colIndex);
        },
        sessionAdd(dayOfWeek, seasonKey) {
            // this.record.byDays.every(day => {
            this.tableItems.every(day => {
                if (day.dayOfWeek === dayOfWeek) {
                    day.seasonHours[seasonKey].push(
                        {
                            from: null,
                            to: null
                        }
                    );
                    return false;
                }
                return true;
            });
        },
        sessionRemove(dayOfWeek, seasonKey, index) {
            // this.record.byDays.every(day => {
            this.tableItems.every(day => {
                if (day.dayOfWeek === dayOfWeek) {
                    day.seasonHours[seasonKey].splice(index, 1);
                    return false;
                }
                return true;
            });
        },
        // <-- season -->
        openModalSeason() {
            this.modalBus.$emit('modalOpen', 0);
        },
        updateSeasons(data) {
            this.$emit('eventUpdatePriceListSeasons', data);
        }
    },
    computed: {
        // <-- data -->
        getDaysOfWeek() {
            let days = [];
            let firstDayOfWeek = this.__localeContent.firstDayOfWeek;
            for (let i = 0; i < 7; i++) {
                days.push(firstDayOfWeek > 6 ? firstDayOfWeek - 7 : firstDayOfWeek);
                firstDayOfWeek++;
            }
            return days;
        },
        getPriceListSeasons() {
            let array = this.__clone(this.dataPriceListSeasons);
            // <-- sort -->
            array = array.sort(function (a, b) {
                // <-- default season -->
                if (a.key === 'default') {
                    return 1;
                }
                // <--  -->
                let fa = a.start,
                    fb = b.start;
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            // <--  -->
            return array;
        },
        getOpeningHoursType() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <--  -->
            let data = this.$t('date_time.opening_hours_type');
            Object.keys(data).forEach(key => {
                array.push(
                    {
                        text: data[key],
                        value: key
                    }
                );
            });
            // <--  -->
            return array;
        },
        // <-- table -->
        tableColumns() {
            let columns = this.table.columns;
            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            columns.forEach(column => {
                values.push({
                    key: column.key,
                    label: column.label,
                    class: column.class + ' border'
                });
            });
            // <-- add columns seasons -->
            let dateStart, dateEnd;
            let dateStartString, dateEndString;
            this.getPriceListSeasons.forEach(season => {
                // <-- default season -->
                if (season.key === 'default') {
                    values.push({
                        key: season.key,
                        label: this.$t(`form.default`),
                        class: 'text-center border'
                    });
                }
                // <-- date start -->
                else {
                    dateStart = new Date(season.start);
                    dateStartString = dateStart.toLocaleDateString(this.__getVueLocale(), {
                        month: "numeric",
                        day: "numeric"
                    })
                    // <-- date end -->
                    dateEnd = new Date(season.end);
                    dateEndString = dateEnd.toLocaleDateString(this.__getVueLocale(), {
                        month: "numeric",
                        day: "numeric"
                    })
                    // <--  -->
                    values.push({
                        key: season.key,
                        label: `${dateStartString}–${dateEndString}`,
                        class: 'text-center border'
                    });
                }
            });
            // <--  -->
            return values;
        },
        tableItems() {
            this.fixRecordByDays();
            let array = this.record.byDays;
            // <--  -->
            array.forEach(value => {
                value.dayOfWeekName = this.$t('date_time.days_of_week_name.' + value.dayOfWeek);
            });
            // <--  -->
            return array;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
